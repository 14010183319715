<template>
  <div v-if="!loading" class="pr-3 pb-5 pt-3 flex flex-column flex-auto xhl:pl-5">
    <div class="border-round" :style="viewRH ? 'max-width: calc(95vw - 367.75px)' : ''">
        <div class="flex justify-content-between align-items-center">
          <div class="m-0 mb-4 p-0 flex align-items-center" style="gap: 1.5rem; width: 40%;">
            <Dropdown ref="simulationDropdown" style="width: auto;" v-if="simulations && simulations.length > 0" @change="changeSimulation" v-model="clonedSimulation" :options="simulations">
              <template #value="slotProps">
                <div>
                  V{{ simulations.indexOf(simulations.find((s) => slotProps.value.id === s.id)) + 1 }} {{ new Date(slotProps.value.date_created).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric',}) }}
                </div>
              </template>
              <template #option="slotProps">
                <div>
                  V{{ simulations.indexOf(slotProps.option) + 1 }} {{ new Date(slotProps.option.date_created).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' }) }}
                </div>
              </template>
            </Dropdown>
            <Button v-if="simulation?.independent?.id && me.role.name !== constants.ROLES.INDEPENDENT" type="button"
                    rounded size="small" label="Créer une nouvelle simulation"
                    @click="newSimulation"/>
          </div>
          <div class="flex flex-row mb-1 text-sm gap-1">
            <div>(1) Champs obligatoires pour enregistrer la simulation</div>
            <div>(2) Champs obligatoires pour voir/envoyer la simulation</div>
          </div>
        </div>
        <div class="flex gap-3">
          <div class="flex flex-column left-cards cards gap-3">
            <OuterCardTitle v-if="!$route.params.idPorte" :value="$t('prospect')"/>
            <Card v-if="!$route.params.idPorte">
              <template #content>
                <div class="mb-0 flex flex-column">
                  <div class="field">
                    <label for="independentEmail" :class="emailDisabledFields ? 'font-bold' : ''">
                      {{ $t('email') }}
                      <RequiredValueTips value="(1) (2)"/>
                    </label>
                    <div>
                      <InputText id="independentEmail" v-model="simulation.independent.email" type="text"
                                 class="w-full" :disabled="readOnlyDisabledFields" @blur="checkIndependentEmail" />
                    </div>
                  </div>
                  <div class="field">
                    <label for="independentLastname">{{ $t('lastname') }} <RequiredValueTips value="(2)"/></label>
                    <div>
                      <InputText id="independentLastname" v-model="simulation.independent.last_name" type="text"
                                 class="w-full" :disabled="readOnlyDisabledFields"/>
                    </div>
                  </div>
                  <div class="field">
                    <label for="independentFirstname">{{ $t('firstname') }} <RequiredValueTips value="(2)"/></label>
                    <div>
                      <InputText id="independentFirstname" v-model="simulation.independent.first_name" type="text"
                                 class="w-full" :disabled="readOnlyDisabledFields"/>
                    </div>
                  </div>
                  <div class="field">
                    <label for="independentPhone">{{ $t('phone') }}</label>
                    <div>
                      <InputMask id="independentPhone" v-model="simulation.independent.phone" class="w-full"
                                 mask="99-99-99-99-99" :disabled="readOnlyDisabledFields"/>
                    </div>
                  </div>
                  <div class="field mb-0">
                    <label for="independentJob">{{ $t('job') }}</label>
                    <div>
                      <InputText id="independentJob" v-model="simulation.independent.job" type="text"
                                 class="w-full" :disabled="readOnlyDisabledFields"/>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
            <OuterCardTitle v-if="!$route.params.idPorte" value="Comment nous connaît-il ?" :class="emailDisabledFields ? 'disabled-card' : ''"/>
            <Card v-if="!$route.params.idPorte" :class="emailDisabledFields ? 'disabled-card' : ''">
              <template #content>
                <div class="grid formgrid p-fluid">
                  <div class="field mb-4 col-4 md:col-4">
                    <label for="simulation-source">Source</label>
                    <Dropdown id="simulation-source" v-model="simulation.jsonSource.source" show-clear
                              :options="settings?.Commercial?.sources" :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                  </div>
                  <div class="field mb-4 col-8 md:col-8">
                    <label for="simulation-details">Détails</label>
                    <InputText id="simulation-details" v-model="simulation.jsonSource.value" :disabled="emailDisabledFields || readOnlyDisabledFields"
                               type="text" class="w-full"/>
                  </div>
                </div>
              </template>
            </Card>
            <OuterCardTitle value="Missions" :class="emailDisabledFields ? 'disabled-card' : ''"/>
            <Card :class="emailDisabledFields ? 'disabled-card' : ''">
              <template #content>
                <div class="mb-0 flex flex-column">
                  <div v-for="(mission, index) in simulation.jsonMissions.missions" :key="index" class="grid formgrid p-fluid mb-2">
                    <div class="flex justify-content-between align-items-center col-12">
                      <p class="m-0 mb-2 p-0 text-md font-bold line-height-3 mr-3">Mission {{ index + 1 }} :</p>
                      <i v-if="simulation.jsonMissions.missions.length > 1" class="pi pi-times-circle cursor-pointer" style="font-size: 1.5rem" @click="removeMission(index)"></i>
                    </div>
                    <div class="field mb-4 col-3 md:col-3">
                      <label for="simulation-details">Taux de facturation</label>
                      <Dropdown v-model="mission.type" :options="constants.data.negotiatedRateUnitOptions"
                                :disabled="emailDisabledFields || readOnlyDisabledFields" class="mr-2"
                                @change="changeType(mission)"/>
                    </div>
                    <div class="field mb-4 col-9 md:col-9">
                      <label for="simulation-details" class="">
                        Montant en €
                        <RequiredValueTips value="(2)"/>
                      </label>
                      <div class="flex flex-row gap-1 align-items-start">
                        <InputNumber :id="'missionBase' + index" v-model="mission.base" highlightOnFocus
                                     :min="0" locale="fr-FR" mode="currency" currency="EUR"
                                     :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                      </div>
                    </div>
                    <div class="field mb-4 col-12 md:col-12" v-if="mission.type !== constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE">
                      <label :for="'missionCoefInvoiced' + index">
                        {{ mission.type === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE ? 'Nombre de jours facturés' : 'Nombre d\'heures facturées' }}
                        <RequiredValueTips value="(2)"/>
                      </label>
                      <InputNumber
                        :id="'missionCoefInvoiced' + index"
                        v-model="mission.coefInvoiced"
                        :suffix="invoicedDaySuffix[index]"
                        :disabled="emailDisabledFields || readOnlyDisabledFields"
                        highlightOnFocus
                        @input="(event) => invoicedDayChanged(event, index)"
                        @blur="(event) => invoicedDayChanged(event, index)"
                      />
                    </div>
                    <div class="field mb-4 col-12 md:col-12">
                      <label :for="'missionCoefPaid' + index">
                        {{ mission.type === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE ? 'Nombre de jours payés' : (mission.type === constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE ? 'Nombre d\'heures payés' : 'Coefficient payé') }}
                        <RequiredValueTips value="(2)"/>
                      </label>
                      <InputNumber
                        :id="'missionCoefPaid' + index"
                        v-model="mission.coefPaid"
                        :disabled="emailDisabledFields || readOnlyDisabledFields"
                        :suffix="payedDaySuffix[index]"
                        highlightOnFocus
                        @input="(event) => payedDayChanged(event, index)"
                        @blur="(event) => payedDayChanged(event, index)"
                      />
                    </div>
                  </div>
                  <Divider align="right">
                    <Button label="Ajouter une mission" icon="pi pi-plus" class="p-button-outlined" @click="addMission" :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                  </Divider>
                  <div class="grid formgrid p-fluid">
                    <div class="field mb-4 col-12 md:col-12">
                      <label for="missionRevenue">Chiffre d'affaires</label>
                      <InputNumber id="missionRevenue" :model-value="missionRevenueComputed" locale="fr-FR" mode="currency" currency="EUR" disabled highlightOnFocus/>
                    </div>
                    <div class="field mb-4 col-4 md:col-4">
                      <label for="missionFee">Tarif de gestion <RequiredValueTips value="(2)"/></label>
                      <InputNumber id="missionFee" v-model="simulation.jsonMissions.managementFee.value" suffix=" %"
                                   :max-fraction-digits="2" mode="decimal" :disabled="emailDisabledFields || readOnlyDisabledFields"
                                   highlightOnFocus/>
                    </div>
                    <div class="field mb-4 col-4 md:col-4">
                      <label for="managementFeeResult">Frais de gestion</label>
                      <InputNumber id="managementFeeResult" :model-value="managementFeeResultComputed"
                                   disabled locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
                    </div>
                    <div v-if="settings?.Commercial?.tarifGestion?.plafond" class="field mb-4 col-4 md:col-4">
                      <label for="managementFeeCap">Plafond</label>
                      <InputNumber id="managementFeeCap" v-model="simulation.jsonMissions.managementFee.cap" highlightOnFocus
                                   locale="fr-FR" mode="currency" currency="EUR" :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                      <div v-if="typeof simulation.jsonMissions.managementFee.cap === 'number' && simulation.jsonMissions.managementFee.cap === 0"
                           class="flex flex-row align-items-center gap-2 text-orange-400">
                        <i class="pi pi-exclamation-circle text-xl"/>
                        <span>Plafond à zéro</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
            <OuterCardTitle v-if="settings.Entreprise.convention === constants.COLLECTIVE_CONVENTION.PORTAGE" value="Réserve financière" :class="emailDisabledFields ? 'disabled-card' : ''"/>
            <Card v-if="settings.Entreprise.convention === constants.COLLECTIVE_CONVENTION.PORTAGE" :class="emailDisabledFields ? 'disabled-card' : ''">
              <template #content>
                <div class="flex flex-row justify-content-between align-items-center">
<!--                  <p class="m-0 mb-2 p-0 text-xl font-bold line-height-3 mr-3">Réserve financière :</p>-->
                  <label for="financialReserve">Statut : <RequiredValueTips value="(2)"/></label>
                  <Dropdown
                      id="financialReserve"
                      v-model="simulation.jsonMissions.status"
                      class="w-5" show-clear
                      :option-label="(val) => `${val.type}`"
                      :options="formatFinancialReservesToArray"
                      :disabled="emailDisabledFields || readOnlyDisabledFields"
                  >
                    <template #value="slotProps">
                      <span :style="!slotProps?.value?.type ? 'visibility: hidden' : ''">
                        {{ slotProps?.value?.type ?? 'p-emptylabel' }}
                      </span>
                    </template>
                  </Dropdown>
                </div>
              </template>
            </Card>
            <OuterCardTitle :value="$t('contract')" :class="emailDisabledFields ? 'disabled-card' : ''"/>
            <Card :class="emailDisabledFields ? 'disabled-card' : ''">
              <template #content>
                <div class="grid formgrid p-fluid">
                  <div class="field col-12 md:col-12">
                    <label for="workContract">Contrat de travail : <RequiredValueTips value="(2)"/></label>
                    <div id="workContract" class="flex gap-2 justify-content-center align-items-center">
                      <div v-if="settings?.Commercial?.contrat?.cdd === true" class="flex gap-1 align-items-center">
                        <label for="cdd">CDD</label>
                        <RadioButton id="cdd" v-model="simulation.jsonMissions.workContract" :disabled="emailDisabledFields || readOnlyDisabledFields"
                                     name="contract" :value="constants.WORK_CONTRACT_TYPE.CDD"/>
                      </div>
                      <div v-if="settings?.Commercial?.contrat?.cdi === true" class="flex gap-1 align-items-center">
                        <label for="cdi">CDI</label>
                        <RadioButton id="cdi" v-model="simulation.jsonMissions.workContract" :disabled="emailDisabledFields || readOnlyDisabledFields"
                                     name="contract" :value="constants.WORK_CONTRACT_TYPE.CDI"/>
                      </div>
                    </div>
                  </div>
                  <!--                <div class="field flex">-->
                  <!--                  <label for="workContract">Contrat de travail : <RequiredValueTips value="(2)"/></label>-->
                  <!--                  <div id="workContract" class="flex" style="gap: 1rem; align-items: center">-->
                  <!--                    <div v-if="settings?.Commercial?.contrat?.cdd === true">-->
                  <!--                      <label for="cdd" class="mr-2">CDD</label>-->
                  <!--                      <RadioButton id="cdd" name="contract" :value="constants.WORK_CONTRACT_TYPE.CDD" v-model="simulation.jsonMissions.workContract" :disabled="emailDisabledFields || readOnlyDisabledFields"/>-->
                  <!--                    </div>-->
                  <!--                    <div v-if="settings?.Commercial?.contrat?.cdi === true">-->
                  <!--                      <label for="cdi" class="mr-2">CDI</label>-->
                  <!--                      <RadioButton id="cdi" name="contract" :value="constants.WORK_CONTRACT_TYPE.CDI" v-model="simulation.jsonMissions.workContract" :disabled="emailDisabledFields || readOnlyDisabledFields"/>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                </div>-->
                  <div class="field mb-4 col-8 md:col-8">
                    <label for="workTime">Temps de travail : <RequiredValueTips value="(2)"/></label>
                    <div class="flex flex-column">
                      <div id="workTime" class="flex gap-2 justify-content-center align-items-center">
                        <div class="flex gap-1 align-items-center">
                          <label>Temps plein</label>
                          <RadioButton v-model="simulation.jsonMissions.workTime.type" :value="constants.WORK_TIME.FULL" :disabled="emailDisabledFields || readOnlyDisabledFields" @click="changeToFullWorkTime"/>
                        </div>
                        <div class="flex gap-1 align-items-center">
                          <label>Temps partiel</label>
                          <RadioButton v-model="simulation.jsonMissions.workTime.type" :value="constants.WORK_TIME.PARTIAL" :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                        </div>
                      </div>
                      <!--  todo: see if we need to add the description and the cost per hour  -->
                    </div>
                  </div>
                  <div class="field mb-4 col-4 md:col-4">
                    <label for="workTime">Total : <RequiredValueTips value="(2)"/></label>
                    <InputNumber v-model="simulation.jsonMissions.workTime.value" suffix=" h" highlightOnFocus :min="1"
                                 :disabled="(emailDisabledFields || readOnlyDisabledFields) || simulation.jsonMissions.workTime.type === constants.WORK_TIME.FULL"/>
                  </div>
                  <div class="field mb-4 col-12 md:col-6">
                    <label for="mutual">Mutuelle :</label>
                    <Dropdown id="mutual" v-model="simulation.jsonMissions.mutual" :options="constants.data.mutualOptions"
                              option-value="value" option-label="name" :disabled="emailDisabledFields || readOnlyDisabledFields"
                              show-clear/>
                  </div>
                  <div v-if="settings.RH.ticketsRestaurant.value" class="field mb-4 col-12 md:col-6">
                    <label for="missionTicket">Ticket restaurant</label>
                    <InputNumber id="missionTicket" v-model="simulation.jsonMissions.ticket" :min="0" :disabled="emailDisabledFields || readOnlyDisabledFields" highlightOnFocus/>
                  </div>
                  <div class="field mb-4 col-12">
                    <label for="missionpasRate">Taux PAS</label>
                    <InputNumber id="missionpasRate" suffix=" %" v-model="simulation.jsonMissions.pasRate" :min="0"
                                 mode="decimal" :minFractionDigits="2" :maxFractionDigits="4" highlightOnFocus
                                 :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                  </div>
                </div>
              </template>
            </Card>
            <OuterCardTitle value="Chargé commercial" :class="emailDisabledFields ? 'disabled-card' : ''"/>
            <Card class="p-0" :class="emailDisabledFields ? 'disabled-card' : ''">
              <template #content >
                <div class="grid formgrid p-fluid">
                  <div class="field mb-4 col-12">
                    <label for="workTime">Membre : <RequiredValueTips value="(1) (2)"/></label>
                    <Dropdown v-model="simulation.commercial" :options="commercials"
                              :option-label="(val) => val.first_name + ' ' + val.last_name"
                              :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                  </div>
                </div>
              </template>
            </Card>
          </div>
          <div class="flex flex-column right-cards cards gap-3">
            <OuterCardTitle value="Transports" :class="emailDisabledFields ? 'disabled-card' : ''"/>
            <Card :class="emailDisabledFields ? 'disabled-card' : ''">
              <template #content>
                <div v-if="transportOptions?.length > 0">
                  <div v-for="(transport, index) in simulation.jsonTransports" :key="index" class="grid formgrid p-fluid mb-2">
                    <div class="flex justify-content-between align-items-center col-12">
                      <p class="m-0 mb-2 p-0 text-md font-bold line-height-3 mr-3">
                        Transport {{ index + 1 }} :
                      </p>
                      <i v-if="simulation.jsonTransports.length > 1" class="pi pi-times-circle cursor-pointer"
                         style="font-size: 1.5rem" @click="removeTransport(index)"/>
                    </div>
                    <div class="field mb-4 col-12">
                      <label :for="'transportType' + index">Type</label>
                      <Dropdown :id="'transportType' + index" v-model="transport.mode" :options="transportOptions"
                                :disabled="emailDisabledFields || readOnlyDisabledFields"
                                :show-clear="simulation.jsonTransports.length === 1"
                                class="w-full" placeholder="Selectionner un transport"
                                option-value="value" option-label="label" @change="resetTransport(index)"/>
                    </div>
                    <div v-if="transport.mode === constants.IKM_TRANSPORT_TYPE.PUBLIC_TRANSPORT.value" class="field mb-4 col-6">
                      <label :for="'transportCity' + index">Ville</label>
                      <InputText :id="'transportCity' + index" v-model="transport.cityPublicTransport"
                                 :disabled="emailDisabledFields || readOnlyDisabledFields" type="text"/>
                    </div>
                    <div v-if="transport.mode === constants.IKM_TRANSPORT_TYPE.PUBLIC_TRANSPORT.value" class="field mb-4 col-6">
                      <label :for="'transportFixedPriced' + index">Montant de l'abonnement</label>
                      <InputText :id="'transportFixedPriced' + index" v-model="transport.fixedPricePublicTransport"
                                 :disabled="emailDisabledFields || readOnlyDisabledFields" type="text"/>
                    </div>
                    <div v-if="transport.mode !== constants.IKM_TRANSPORT_TYPE.PUBLIC_TRANSPORT.value && transport.mode !== null"
                         class="field mb-4 col-12 md:col-6">
                      <label :for="'transportElectric' + index">Véhicule électrique</label>
                      <div>
                        <Checkbox :id="'transportElectric' + index" v-model="transport.electric" :binary="true"
                                  :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                      </div>
                    </div>
                    <div v-if="transport.mode !== constants.IKM_TRANSPORT_TYPE.PUBLIC_TRANSPORT.value && transport.mode !== null"
                         class="field mb-4 col-12 md:col-6">
                      <label :for="'transportVehicle' + index">Véhicule</label>
                      <InputText :id="'transportVehicle' + index" type="text" v-model="transport.vehicle" :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                    </div>
                    <div v-if="transport.mode !== constants.IKM_TRANSPORT_TYPE.PUBLIC_TRANSPORT.value && transport.mode !== null"
                         class="field mb-4 col-12 md:col-6">
                      <label :for="'transportHorsePower' + index">Chevaux</label>
                      <InputText :id="'transportHorsePower' + index" type="text" v-model="transport.horsePower" :disabled="emailDisabledFields || readOnlyDisabledFields || transport.mode === 'motorcycle'"/>
                    </div>
                    <div v-if="transport.mode !== constants.IKM_TRANSPORT_TYPE.PUBLIC_TRANSPORT.value && transport.mode !== null"
                         class="field mb-4 col-12 md:col-6">
                      <label :for="'transportTripsNumber' + index">Nombre de trajets</label>
                      <InputText :id="'transportTripsNumber' + index" type="text" v-model="transport.tripsNumber" :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                    </div>
                    <div v-if="transport.mode !== constants.IKM_TRANSPORT_TYPE.PUBLIC_TRANSPORT.value && transport.mode !== null"
                         class="field mb-4 col-12 md:col-6">
                      <label :for="'transportLength' + index">Distance (aller - retour)</label>
                      <InputText :id="'transportLength' + index" type="text" v-model="transport.distance" :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                    </div>
                    <div v-if="transport.mode !== constants.IKM_TRANSPORT_TYPE.PUBLIC_TRANSPORT.value && transport.mode !== null"
                         class="field mb-4 col-12 md:col-6">
                      <label :for="'transportTotalLength' + index">Distance totale</label>
                      <InputText :id="'transportTotalLength' + index" type="text" :model-value="transport.tripsNumber * transport.distance" disabled/>
                    </div>
                    <div v-if="transport.mode !== constants.IKM_TRANSPORT_TYPE.PUBLIC_TRANSPORT.value && transport.mode !== null"
                         class="field col-12 md:col-6">
                      <label :for="'transportIKTotal' + index">Montant total IK</label>
                      <InputText :id="'transportIKTotal' + index" type="text" :model-value="totalIkmsComputed[index]" disabled/>
                    </div>
                  </div>
                  <div class="text-right">
                    <Button v-if="simulation.jsonTransports[0]?.mode !== null" label="Ajouter un transport" icon="pi pi-plus" class="p-button-outlined"
                            :disabled="emailDisabledFields || readOnlyDisabledFields" @click="addTransport"/>
                  </div>
                </div>
                <div v-else>
                  Votre société ne propose d'indemnité pour aucun transport
                </div>
              </template>
            </Card>
            <OuterCardTitle value="Provision d'autres charges" :class="emailDisabledFields ? 'disabled-card' : ''"/>
            <Card :class="emailDisabledFields ? 'disabled-card' : ''">
              <template #content>
                <div v-for="(provision, index) in simulation.jsonProvisions" :key="index" class="grid formgrid p-fluid mb-2">
                  <div class="flex justify-content-between align-items-center col-12">
                    <p class="m-0 mb-2 p-0 text-md font-bold line-height-3 mr-3">
                      Provision {{ index + 1 }} :
                    </p>
                    <i v-if="simulation.jsonProvisions.length > 1" class="pi pi-times-circle cursor-pointer"
                       style="font-size: 1.5rem" @click="removeProvision(index)"/>
                  </div>
                  <div class="field mb-4 col-12 md:col-6">
                    <label :for="'provisionReason' + index">Motif</label>
                    <InputText :id="'provisionReason' + index" type="text" v-model="provision.reason"
                               :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                  </div>
                  <div class="field mb-4 col-12 md:col-6">
                    <label :for="'provisionValue' + index">Montant</label>
                    <InputNumber :id="'provisionValue' + index" v-model="provision.value" locale="fr-FR"
                                 mode="currency" currency="EUR" :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                  </div>
                </div>
                <div class="text-right">
                  <Button label="Ajouter une provision" icon="pi pi-plus" class="p-button-outlined" @click="addProvision" :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                </div>
              </template>
            </Card>
            <OuterCardTitle value="Petits / Grands déplacements" :class="emailDisabledFields ? 'disabled-card' : ''"/>
            <Card :class="emailDisabledFields ? 'disabled-card' : ''">
              <template #content>
                <div class="grid formgrid p-fluid">
                  <div class="flex justify-content-between align-items-center col-12">
                    <p class="m-0 mb-2 p-0 text-md font-bold line-height-3 mr-3">
                      Hotel :
                    </p>
                  </div>
                  <div class="field mb-4 col-12 md:col-4">
                    <label for="prixDeplacementsHotel">Barème</label>
                    <Dropdown id="prixDeplacementsHotel" ref="simulationDropdown"
                              v-model="simulation.jsonDeplacements.hotel.price" :options="forfaitsHotelComputed"
                              :disabled="emailDisabledFields || readOnlyDisabledFields" showClear
                              option-label="label" option-value="fixedPrice">
                      <template #value="slotProps">
                        <InputNumber v-model="slotProps.value" locale="fr-FR" mode="currency" currency="EUR"
                                     disabled input-class="border-none p-0"/>
                      </template>
                    </Dropdown>
                  </div>

                  <div class="flex gap-2 mb-3 align-items-center col-12">
                    <label class="text-md font-bold">
                      Repas midi :
                    </label>
                    <label>
                      Midi ({{ settings.Paie.avantagesSociaux.forfaitRepasMidi ? (settings.Paie.avantagesSociaux.forfaitRepasMidi / 100).toFixed(2) : 0 }} €)
                    </label>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="nombreDeplacementsLunch">Nombre</label>
                    <InputNumber id="nombreDeplacementsLunch" v-model="simulation.jsonDeplacements.lunch.quantity"
                                 :min="0" :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="totalLunch">Total</label>
                    <InputNumber id="totalLunch" disabled v-model="lunchTotal" locale="fr-FR" mode="currency" currency="EUR"/>
                  </div>
                  <div class="flex gap-2 mb-3 align-items-center col-12">
                    <label class="text-md font-bold">
                      Repas soir :
                    </label>
                    <label>
                      Soir ({{ settings.Paie.avantagesSociaux.forfaitRepasSoir ? (settings.Paie.avantagesSociaux.forfaitRepasSoir / 100).toFixed(2) : 0 }} €)
                    </label>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="nombreDeplacementsDinner">Nombre</label>
                    <InputNumber id="nombreDeplacementsDinner" v-model="simulation.jsonDeplacements.dinner.quantity"
                                 :min="0" :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="totalDinner">Total</label>
                    <InputNumber id="totalDinner" class="inputnumber-medium ml-2" disabled v-model="dinnerTotal"
                                 locale="fr-FR" mode="currency" currency="EUR"/>
                  </div>
                </div>
              </template>
            </Card>
            <Card :class="emailDisabledFields ? 'disabled-card' : ''">
              <template #content>
                <div class="grid formgrid p-fluid">
                  <div class="field col-12 md:col-6">
                    <label for="homeLocation">Lieu de domicile</label>
                    <InputText id="homeLocation" type="text" v-model="simulation.homeLocation"
                               class="w-full" :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="missionLocation">Lieu de mission</label>
                    <InputText id="missionLocation" type="text" v-model="simulation.missionLocation"
                               class="w-full" :disabled="emailDisabledFields || readOnlyDisabledFields"/>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
  </div>
  <div v-else class="overflow-hidden flex">
    <ProgressSpinner></ProgressSpinner>
  </div>
  <dialog-confirm
      :is-active="confirmDialog"
      :refuse-action="refuseAction"
      :validate-action="validateAction"
      :text-question="textQuestion"
      :text-info="textInfo"
      :title="title"
  />
</template>

<script>
import _ from 'lodash'
import {mapState} from "vuex";
import DialogConfirm from "@/components/DialogConfirm";
import OuterCardTitle from "@/components/OuterCardTitle";
import {formatFinancialReserveToArray} from "@/utils/RhUtil";
import {hasAccess} from "@/utils/Misc";
import RequiredValueTips from "@/components/RequiredValueTips";

export default {
  name: "FicheSimulation",
  components: {RequiredValueTips, DialogConfirm, OuterCardTitle },
  emits: ["canGenerate", "canSave", "canSend"],
  props: {
    viewRH: {
      type: Boolean,
      default: false
    },
    emptySimulation: { // mean that there's no existing data for this simulation
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hasAccess: hasAccess,
      loading: true,
      clonedSimulation: {},
      filteredClients: [],
      transportOptions: [],

      emailInvalid: false,
      independentEmailDuplicate: '',

      confirmDialog: false,
      refuseAction: () => {},
      validateAction: () => {},
      textQuestion: '',
      textInfo: '',
      title: '',

      invoicedDaySuffix: [],
      payedDaySuffix: []
    }
  },
  watch: {
    simulation: {
      handler () {
        this.handleSimulation()
      },
      deep: true
    },
    '$route.params.idSimulation': {
      handler () {
        this.$store.state.misc.loading = true
        let simu = this.simulations.find((s) => s.id === this.$route.params.idSimulation)
        if (simu) {
          this.$store.commit('simulation/SET_SIMULATION', simu)
        }
        this.$store.state.misc.loading = false
      },
      deep: true
    },
  },
  computed: {
    ...mapState({
      settings: state => state.misc.settings,
      me: state => state.auth.me,
      commercials: state => state.commercial.commercials,
      clients: state => state.client.clients,
      simulation: state => state.simulation.simulation,
      simulations: state => state.simulation.simulations,
      constants: state => state.constants
    }),
    forfaitsHotelComputed() {
      return this.settings.Paie.avantagesSociaux.forfaitHotels.map((forfaitHotel) => {
        return {
          ...forfaitHotel,
          fixedPrice: forfaitHotel.fixedPrice / 100
        }
      })
    },
    distancesTotalesComputed () {
      const distancesTotales = []
      this.simulation.jsonTransports.forEach((transport) => {
        distancesTotales.push(parseFloat(transport.distance) * parseFloat(transport.tripsNumber))
      })
      return distancesTotales
    },
    totalIkmsComputed () {
      const ikms = []
      this.simulation.jsonTransports.forEach((transport, index) => {
        const result = this.getBareme(transport)
        if (transport.mode === this.constants.IKM_TRANSPORT_TYPE.PUBLIC_TRANSPORT.value) {
          ikms.push(0)
        } else if (result.value && this.distancesTotalesComputed[index]) {
          ikms.push((result.value * this.distancesTotalesComputed[index] + result.c).toFixed(2))
        } else {
          ikms.push(0)
        }
      })
      return ikms
    },
    managementFeeResultComputed () {
      let value = (this.simulation.jsonMissions.managementFee.value / 100) * this.simulation.jsonMissions.missions.reduce((total, arg) => total + (arg.base * arg.coefInvoiced), 0)
      return isNaN(value) ? undefined : value
    },
    missionRevenueComputed () {
      let value = this.simulation.jsonMissions.missions.reduce((total, arg) => {
        return total + ((!arg.base ? 0 : arg.base) * (!arg.coefInvoiced ? 0 : arg.coefInvoiced))
      }, 0)
      return isNaN(value) ? undefined : value
    },
    hotelTotal () {
      let value = this.simulation.jsonDeplacements.hotel.quantity * this.simulation.jsonDeplacements.hotel.price
      return isNaN(value) ? undefined : value
    },
    lunchTotal () {
      let value = ((this.simulation.jsonDeplacements.lunch.quantity * this.settings.Paie.avantagesSociaux.forfaitRepasMidi) / 100).toFixed(2)
      return isNaN(value) ? undefined : value
    },
    dinnerTotal () {
      let value = ((this.simulation.jsonDeplacements.dinner.quantity * this.settings.Paie.avantagesSociaux.forfaitRepasSoir) / 100).toFixed(2)
      return isNaN(value) ? undefined : value
    },
    emailDisabledFields () {
      return this.emailInvalid
    },
    readOnlyDisabledFields () {
      return this.readOnly
    },
    formatFinancialReservesToArray () {
      return formatFinancialReserveToArray(this.settings.Commercial.tauxReserveFinanciere)
    }
  },
  async created () {
    if (this.emptySimulation) { this.emailInvalid = true }
    // todo: manage the case where there is no transport at all
    // not using directly the constants ikm transport type subobject in case we wanna use the label as a key for translation
    if (this.settings?.Commercial?.transport?.transports === true) this.transportOptions.push({ label: this.constants.IKM_TRANSPORT_TYPE.PUBLIC_TRANSPORT.label, value: this.constants.IKM_TRANSPORT_TYPE.PUBLIC_TRANSPORT.value })
    if (this.settings?.Commercial?.transport?.voiture === true) this.transportOptions.push({ label: this.constants.IKM_TRANSPORT_TYPE.CAR.label, value: this.constants.IKM_TRANSPORT_TYPE.CAR.value })
    if (this.settings?.Commercial?.transport?.moto === true) this.transportOptions.push({ label: this.constants.IKM_TRANSPORT_TYPE.MOTORBIKE.label, value: this.constants.IKM_TRANSPORT_TYPE.MOTORBIKE.value })
    if (this.settings?.Commercial?.transport?.cyclomoteur === true) this.transportOptions.push({ label: this.constants.IKM_TRANSPORT_TYPE.MOTORCYCLE.label, value: this.constants.IKM_TRANSPORT_TYPE.MOTORCYCLE.value })

    if (this.me.role.name !== this.constants.ROLES.INDEPENDENT) {
      await this.$store.dispatch('client/getAllClients')
    }
    await this.$store.dispatch('commercial/getAllCommercials')

    if (!_.isEqual(this.simulation, {})) { // todo: this can cause issues ? No more ref on the parent props, have to resend data child to parent ?
      this.cloneSimulation()
    } else {
      this.simulation.jsonMissions = {
        missions: [
          {
            type: this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE,
            base: undefined,
            coefPaid: undefined,
            coefInvoiced: undefined,
          }
        ],
        workTime: {
          type: this.constants.WORK_TIME.FULL,
          value: this.settings.Paie.workTime.workingHoursOnFullTime
        },
        workContract: '',
        mutual: undefined,
        ticket: undefined,
        pasRate: 0,
        managementFee: {
          value: undefined,
          cap: undefined
        }
      }
      this.simulation.jsonTransports = this.transportOptions?.length > 0 ? [
        {
          mode: this.transportOptions[0].value,
          vehicle: '',
          electric: false,
          horsePower: '',
          tripsNumber: '',
          distance: '',
          cityPublicTransport: '',
          fixedPricePublicTransport: '',
        }
      ] : []
      this.simulation.jsonContract = {}
      this.simulation.missionLocation = ''
      this.simulation.homeLocation = ''
      this.simulation.jsonSource = {
        source: '',
        value: ''
      }
      this.simulation.jsonProvisions = [
        {
          reason: '',
          value: 0
        }
      ]
      this.simulation.jsonDeplacements = {
        hotel: {
          quantity: undefined,
          price: undefined
        },
        lunch: {
          quantity: undefined,
          price: undefined
        },
        dinner: {
          quantity: undefined,
          price: undefined
        }
      }
      this.simulation.commercial = {
        firstname: '',
        lastname: ''
      }
      this.simulation.independent = {
        first_name: '',
        last_name: '',
        email: '',
        phone: undefined,
        job: ''
      }
    }
    if (this.$route.query.idProspect || this.$route.params.idPorte) {
      this.simulation.independent = await this.$store.dispatch('independent/getOneIndependent', this.$route.query.idProspect ?? this.$route.params.idPorte)
    }
    if (this.simulation.independent) {
      // save a duplicate of the email, in case it changed and isn't accepted
      this.independentEmailDuplicate = JSON.parse(JSON.stringify(this.simulation?.independent?.email))
    }
    this.handleSimulation()
    this.initMissionSuffix()
    this.loading = false
  },
  methods: {
    initMissionSuffix () {
      this.simulation.jsonMissions.missions.forEach((mission, index) => {
        this.setupSuffix(mission.coefPaid, index, true)
        this.setupSuffix(mission.coefInvoiced, index)
      })
    },
    setupSuffix (value, missionIndex, payed = false) {
      const suffixKey = payed ? 'payedDaySuffix' : 'invoicedDaySuffix'

      if (payed && this.simulation.jsonMissions.missions[missionIndex].type === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE) {
        this.payedDaySuffix[missionIndex] = ' %'
      }

      if (this.simulation.jsonMissions.missions[missionIndex].type === this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE) {
        this[suffixKey][missionIndex] = value > 1 ? ' Jours' : ' Journée'
      } else if (this.simulation.jsonMissions.missions[missionIndex].type === this.constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE) {
        this[suffixKey][missionIndex] = value > 1 ? ' Heures' : ' Heure'
      }
    },
    // eslint-disable-next-line no-unused-vars
    invoicedDayChanged (event, missionIndex) {
      process.nextTick(() => {
        const newValue = typeof event.value === 'string' ? parseInt(event.value) : event.value
        if (newValue < 0 || isNaN(newValue)) {
          this.simulation.jsonMissions.missions[missionIndex].coefInvoiced = 0
          return
        }
        this.setupSuffix(newValue, missionIndex)
        this.simulation.jsonMissions.missions[missionIndex].coefInvoiced = newValue
      })
    },
    payedDayChanged (event, missionIndex) {
      // prevent the select of cache data impossible the first time,
      process.nextTick(() => {
        const newValue = typeof event.value === 'string' ? parseInt(event.value) : event.value
        if (newValue < 0 || isNaN(newValue)) {
          this.simulation.jsonMissions.missions[missionIndex].coefPaid = 0
          return
        }
        this.setupSuffix(newValue, missionIndex, true)
        this.simulation.jsonMissions.missions[missionIndex].coefPaid = newValue
      })
    },
    getBareme (transport) {
      const coefficientsDto = {
        transportType: ''
      }
      const majoration = transport.electric ? 1 + this.settings.NDF.baremesIK.majorationElectrique : 1
      let result
      switch (transport.mode) {
        case this.constants.IKM_TRANSPORT_TYPE.CAR.value:
          coefficientsDto.transportType = this.constants.IKM_TRANSPORT_TYPE.CAR.value
          switch (parseInt(transport.horsePower)) { // *1.settings
            case 1:
            case 2:
            case 3:
              result = this.getCoeffsFromSettings(coefficientsDto, 0)
              break
            case 4:
              result = this.getCoeffsFromSettings(coefficientsDto, 1)
              break
            case 5:
              result = this.getCoeffsFromSettings(coefficientsDto, 2)
              break
            case 6:
              result = this.getCoeffsFromSettings(coefficientsDto, 3)
              break
            default:
              result = this.getCoeffsFromSettings(coefficientsDto, 4)
              break
          }
          Object.keys(result).forEach(key => { result[key] *= majoration })
          return result
        case this.constants.IKM_TRANSPORT_TYPE.MOTORBIKE.value:
          coefficientsDto.transportType = this.constants.IKM_TRANSPORT_TYPE.MOTORBIKE.value
          switch (parseInt(transport.horsePower)) {
            case 1:
            case 2:
              result = this.getCoeffsFromSettings(coefficientsDto, 0)
              break
            case 3:
            case 4:
            case 5:
              result = this.getCoeffsFromSettings(coefficientsDto, 1)
              break
            default:
              result = this.getCoeffsFromSettings(coefficientsDto, 2)
              break
          }
          Object.keys(result).forEach(key => { result[key] *= majoration })
          return result
        case this.constants.IKM_TRANSPORT_TYPE.MOTORCYCLE.value:
          result = this.settings.NDF.baremesIK.motorcycles.default === 1 ?
            { value: this.settings.NDF.baremesIK.motorcycles.coefficients[0].firstCol, c: 0 }
            : this.settings.NDF.baremesIK.motorcycles.default === 2 ?
              { value: this.settings.NDF.baremesIK.motorcycles.coefficients[0].secondCol, c: this.settings.NDF.baremesIK.motorcycles.coefficients[0].thirdCol }
              : { value: this.settings.NDF.baremesIK.motorcycles.coefficients[0].fourthCol, c: 0 }
          Object.keys(result).forEach(key => { result[key] *= majoration })
          return result
      }
    },
    getCoeffsFromSettings ({ transportType }, i) {
      const defaultBareme = this.settings.NDF.baremesIK[transportType].default
      const coefficients = {
        value: 0,
        c: 0
      }
      switch (defaultBareme) {
        case 1:
          coefficients.value = this.settings.NDF.baremesIK[transportType].coefficients[i].untilFiveThousandCoefficientN
          coefficients.c = 0
          break
        case 2:
          coefficients.value = this.settings.NDF.baremesIK[transportType].coefficients[i].fiveThousandUntilTwentyThousandCoefficientN
          coefficients.c = this.settings.NDF.baremesIK[transportType].coefficients[0].fiveThousandUntilTwentyThousandCoefficientNPlusOne
          break
        default:
          coefficients.value = this.settings.NDF.baremesIK[transportType].coefficients[i].moreThanTwentyThousandCoefficientN
          coefficients.c = 0
          break
      }
      return coefficients
    },
    resetTransport (index) {
      if (this.simulation.jsonTransports[index].mode === this.constants.IKM_TRANSPORT_TYPE.PUBLIC_TRANSPORT.value) {
        this.simulation.jsonTransports[index].vehicle = ''
        this.simulation.jsonTransports[index].horsePower = ''
        this.simulation.jsonTransports[index].tripsNumber = ''
        this.simulation.jsonTransports[index].distance = ''
        this.simulation.jsonTransports[index].electric = false
      } else {
        this.simulation.jsonTransports[index].cityPublicTransport = ''
        this.simulation.jsonTransports[index].fixedPricePublicTransport = ''
      }
    },
    handleSimulation () {
      let canSave = false
      let canSend = false
      let canGenerate = false

      if (this.simulation.commercial) {
        this.commercials.forEach((c) => {
          if (c.id === this.simulation.commercial.id) {
            this.simulation.commercial = c
          }
        })
      }

      const clearUselessDiff = (simulation) => {
        // delete the independent of both due to no specific interaction with, they differ through -- date_updated/sent_date/status/user_updated same for those which are different after each send/save
        delete simulation.independent
        delete simulation.date_updated
        delete simulation.sent_date
        delete simulation.status
        delete simulation.user_updated
        return simulation
      }

      if (this.simulation.id) {
        const duplicateSimulation = JSON.parse(JSON.stringify(this.simulation))
        const duplicateClonedSimulation = JSON.parse(JSON.stringify(this.clonedSimulation))
        if (!_.isEqual(clearUselessDiff(duplicateSimulation), clearUselessDiff(duplicateClonedSimulation)) && this.simulation.commercial) {
          canSave = true
        }
      } else {
        if (!_.isEqual(this.simulation, this.clonedSimulation) && this.simulation.commercial && this.simulation.commercial.id) {
          canSave = true
        }
      }
      if (this.simulation.id && this.simulation.independent.last_name &&
        this.simulation.independent.first_name && this.simulation.independent.email &&
        (this.simulation.jsonMissions.missions.find((m) => m.coefPaid && m.base && m.coefInvoiced)) !== undefined &&
        this.simulation.jsonMissions.managementFee.value && this.simulation.jsonMissions.workContract &&
        this.simulation.jsonMissions.workTime && this.simulation.jsonMissions.workTime.value > 0 &&
        (this.settings.Entreprise.convention === this.constants.COLLECTIVE_CONVENTION.PORTAGE
            ? (this.simulation.jsonMissions?.status?.type === this.constants.INDEPENDENT_WORK_STATUS_PORTAGE.JUNIOR
                || this.simulation.jsonMissions?.status?.type === this.constants.INDEPENDENT_WORK_STATUS_PORTAGE.SENIOR
                || this.simulation.jsonMissions?.status?.type === this.constants.INDEPENDENT_WORK_STATUS_PORTAGE.FIXED_PRICE)
            : true)
      ) {
        canSend = true
        canGenerate = true
      }
      if (canSave) {
        canSend = false
      }

      this.$emit('canGenerate', canGenerate)
      this.$emit('canSave', canSave)
      this.$emit('canSend', canSend)
    },
    cloneSimulation () {
      this.clonedSimulation = JSON.parse(JSON.stringify(this.simulation))
      if (this.clonedSimulation.date_created) {
        this.clonedSimulation.date_created = new Date(this.clonedSimulation.date_created)
      }
      this.$emit('canSave', false)
      this.handleSimulation()
    },
    addMission () {
      this.simulation.jsonMissions.missions.push({
        type: this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE,
        base: undefined,
        coefPaid: undefined,
        coefInvoiced: undefined,
      })
    },
    removeMission (index) {
      this.simulation.jsonMissions.missions.splice(index, 1)
    },
    addProvision () {
      this.simulation.jsonProvisions.push({
        reason: '',
        value: 0
      })
    },
    removeProvision (index) {
      this.simulation.jsonProvisions.splice(index, 1)
    },
    addTransport () {
      if (this.transportOptions?.length > 0) {
        this.simulation.jsonTransports.push({
          mode: this.transportOptions[0].value,
          vehicle: '',
          electric: false,
          horsePower: '',
          tripsNumber: '',
          distance: '',
          cityPublicTransport: '',
          fixedPricePublicTransport: ''
        })
      }
    },
    removeTransport (index) {
      this.simulation.jsonTransports.splice(index, 1)
    },
    searchClient (val) {
      if (!val.query.trim().length) {
        this.filteredClients = [...this.clients];
      }
      else {
        this.filteredClients = this.clients.filter((client) => {
          return client.name.toLowerCase().startsWith(val.query.toLowerCase());
        });
      }
    },
    searchContact (val) {
      if (!val.query.trim().length) {
        this.filteredContacts = [...this.contacts];
      }
      else {
        this.filteredContacts = this.contacts.filter((contact) => {
          return contact.fullName.toLowerCase().startsWith(val.query.toLowerCase());
        });
      }
    },
    async changeSimulation (event) {
      if (this.me.role.name === this.constants.ROLES.INDEPENDENT) {
        this.clonedSimulation = event.value
        this.$emit('changeSimulation', event.value.id)
        return
      }
      await this.$store.dispatch('simulation/setSimulationBySimulationId', event.value.id)
      this.cloneSimulation()
    },
    changeType (mission) {
      if (mission.type === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE) {
        mission.coefInvoiced = 1
      }
      this.initMissionSuffix()
    },
    async newSimulation () {
      this.$store.state.misc.loading = true
      let newSimulation = JSON.parse(JSON.stringify(this.simulation))
      delete newSimulation.id
      delete newSimulation.commercial?.user
      newSimulation.independent = newSimulation.independent?.id ? { id: newSimulation.independent?.id }: newSimulation.independent
      newSimulation = await this.$store.dispatch('simulation/saveSimulation', newSimulation)

      await this.$store.dispatch('simulation/getSimulationsIndependent', this.$route.params.idProspect ?? this.$route.params.idPorte)
      await this.$store.dispatch('simulation/setSimulationBySimulationId', newSimulation.id)
      this.cloneSimulation()
      this.$store.state.misc.loading = false
    },
    async checkIndependentEmail (event) {
      // for now independent cannot change their email
      if (this.me.role.name !== this.constants.ROLES.ADMIN) {
        return
      }

      if (!this.constants.REGEX_VALIDATORS.EMAIL.test(event.target._value)) {
        this.emailInvalid = true
        return
      }

      let result
      try {
        // todo: the check should be done on independent or users rather than on simulations ? In case there's a non linked one or something ?
        result = await this.$store.dispatch('simulation/checkIndependentEmail', { email: event.target._value })
      } catch (e) {
        // can keep that even when the admin rh views
        this.emailInvalid = false
        return
      }

      // in the case it is the rh, should prevent that there's already an independent with the email entered instead of purpose the user to be redirected to the existing one
      // check that this is rh via the route ?
      if (this.$route.name === 'RHSimulation' && this.independentEmailDuplicate !== this.simulation.independent.email) {
        // todo: can only refuse for now, no possibilities of changing email of an existing one?
        this.validateAction = null
        this.refuseAction = () => {
          this.emailInvalid = false // prevent bug
          this.simulation.independent.email = JSON.parse(JSON.stringify(this.independentEmailDuplicate)) // reset independent email
          this.confirmDialog = false
        }
        this.title = 'Porté existant'
        this.textInfo = 'Vous ne pouvez pas utiliser cet email car il est déjà utilisé'
        this.confirmDialog = true
        return
      }

      // for the emptySimulation
      if (this.emptySimulation) {
        // push belong the status of the simulation
        // - validated -> push to porte view
        // - draft or toValidate -> push to prospect view
        this.refuseAction = () => {
          this.emailInvalid = true
          this.simulation.independent.email = '' // reset indep email
          this.confirmDialog = false
        }
        // todo: might need to handle the case where the simulation is validated but there's still no user linked to the independent for the moment ?
        if (result.status === this.constants.SIMULATION_STATUS.VALIDATED) {
          this.validateAction = async () => {
            await this.$router.push({ name: 'FicheSimulationPorte', params: { idPorte: result.independent.id, idSimulation: result.id} })
            this.confirmDialog = false
          }
          this.title = 'Porté existant'
          this.textQuestion = 'Souhaitez-vous être redirigé vers les simulations du porté ?'
          this.textInfo = 'Vous allez être automatiquement redirigé vers les simulations du porté'
        } else if (result.status === this.constants.SIMULATION_STATUS.DRAFT || result.status === this.constants.SIMULATION_STATUS.TO_VALIDATE) {
          this.validateAction = async () => {
            await this.$router.push({ name: 'FicheProspect', params: { idProspect: result.independent.id, idSimulation: result.id} })
            this.confirmDialog = false
          }
          this.title = 'Prospect existant'
          this.textQuestion = 'Souhaitez-vous être redirigé vers les simulations du prospect ?'
          this.textInfo = 'Vous allez être automatiquement redirigé vers les simulations du prospect'
        }
        this.confirmDialog = true
        return
      }
    },
    changeToFullWorkTime (event) {
      if (event) {
        this.simulation.jsonMissions.workTime.value = this.settings.Paie.workTime.workingHoursOnFullTime
      }
    }
  }
}
</script>

<style scoped>
.p-card {
  border-radius: 20px;
}

.left-cards {
  width: 50%;
}

.right-cards {
  width: 50%;
}

.p-card-content > div {
  gap: 0rem;
}

.dropdown-units {
  width: auto;
}

.inputtext-medium {
  width: 6rem;
}
.disabled-card {
  opacity: 0.4;
}
</style>
