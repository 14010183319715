<template>
  <span class="font-normal text-xs">
    {{ value }}
  </span>
</template>

<script>
export default {
  name: "RequiredValueTips",
  props: {
    value: {
      type: String,
      default: '*'
    }
  }
}
</script>

<style scoped>

</style>
